/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate HTTP proxy
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.9.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const SsoProviderKind = {
    Google: 'Google',
    Apple: 'Apple',
    Azure: 'Azure',
    Custom: 'Custom'
};
export function instanceOfSsoProviderKind(value) {
    for (const key in SsoProviderKind) {
        if (Object.prototype.hasOwnProperty.call(SsoProviderKind, key)) {
            if (SsoProviderKind[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function SsoProviderKindFromJSON(json) {
    return SsoProviderKindFromJSONTyped(json, false);
}
export function SsoProviderKindFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function SsoProviderKindToJSON(value) {
    return value;
}
